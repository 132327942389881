import { useEffect, useState } from 'react';
import { PoolV2ApyCachedFragment, useGetPoolsApyCachedQuery } from 'Graphql/schema-v2';
import { useHasDataChanged } from 'Hooks/useHasDataChanged';

/**
 * A hook that merges pool data with APY data from the cached query
 * @param poolsData The pool data from any query
 * @returns The merged pool data with updated APY values
 */
export function usePoolsWithApy<T extends PoolV2ApyCachedFragment>(poolsData: T[] | undefined | null) {
  const { data: apyData } = useGetPoolsApyCachedQuery();
  const [mergedPools, setMergedPools] = useState<T[]>([]);
  const apyDataChanged = useHasDataChanged(apyData, { compareKeys: ['poolV2S'] });
  const poolsDataChanged = useHasDataChanged({ pools: poolsData }, { compareKeys: ['pools'] });

  useEffect(() => {
    if (!poolsData) return;
    if (!apyDataChanged && !poolsDataChanged) return;

    setMergedPools(
      poolsData.map(pool => {
        const poolApy = apyData?.poolV2S.find(p => p.id === pool.id);

        return {
          ...pool,
          weeklyApy: poolApy?.weeklyApy ?? '',
          monthlyApy: poolApy?.monthlyApy ?? '',
        };
      }),
    );
  }, [apyDataChanged, poolsDataChanged]);

  return mergedPools;
}
