import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import { SxProps, Theme } from '@mui/material';

// Constants
import {
  ISSUANCE_RATE_DECIMALS,
  ONE_HUNDRED,
  OT_ISSUANCE_RATE_DECIMALS,
  POOL_IDS,
  TEN,
  UTILIZATION_RATE_DECIMALS,
  ZERO,
  SECONDS_IN_MINUTE,
  APY_DECIMALS,
  PERCENTAGE_DECIMALS,
  RPC_URL,
} from 'Constants';

// Interfaces
import {
  PoolV2EarnFragment,
  PoolV2DelegateFragment,
  PoolV2OverviewFragment,
  PoolV2PositionBasicFragment,
  PoolV2State,
  PoolV2WithdrawalFragment,
  PoolV2BasicFragment,
  PoolMetaV2Fragment,
  PoolV2LenderPoolCardFragment,
  PoolV2ApyCachedFragment,
} from 'Graphql/schema-v2';
import { CountdownToTimestamp } from 'Shared/interfaces';
import { WindowStates } from 'Context/WithdrawalData/WithdrawalData';

// Utils
import { getNowTimestamp } from 'Utils/dates';
import { pluralize } from 'Utils/string';
import { getMonthlyWeeklyOrBenchmarkApy } from 'Context/EarnData/EarnDataUtils';
import { buildPercentageValueInterfaceFromBigNumber } from 'Utils/valueInterface';

export const getCurrentPosition = (
  account: string,
  poolId: string,
  positions: PoolV2PositionBasicFragment[],
): PoolV2PositionBasicFragment | undefined => {
  const positionId = `${account.toLowerCase()}-${poolId.toLowerCase()}`;
  const currentPosition = positions.find(({ id }) => id === positionId);

  return currentPosition;
};

export const getCurrentInterest = (
  domainEnd: string,
  domainStart: string,
  issuanceRate: string,
  issuanceRateDecimals: number,
): BigNumber => {
  const start = +domainStart;
  const end = +domainEnd;
  const now = getNowTimestamp();
  if (now < start) return ZERO; // edge case, now should be after domainStart
  const last = now > end ? end : now; // loanManager does not accrue interest after domainEnd, although late interest continues accruing
  const secondsSinceStart = last - start;
  const issuance = BigNumber.from(issuanceRate); // interest accrual in assets per second
  const decimalsAdjustment = TEN.pow(issuanceRateDecimals); // precision of issuanceRate
  const currentInterest = issuance.mul(secondsSinceStart).div(decimalsAdjustment);

  return currentInterest;
};

export const getAssetsUnderManagement = (
  accountedInterest: string,
  domainEnd: string,
  domainStart: string,
  issuanceRate: string,
  principalOut: string,
  issuanceRateDecimals: number,
): BigNumber => {
  const accounted = BigNumber.from(accountedInterest);
  const current = getCurrentInterest(domainEnd, domainStart, issuanceRate, issuanceRateDecimals);
  const outstandingInterest = accounted.add(current);
  const principal = BigNumber.from(principalOut);
  const assetsUnderManagement = principal.add(outstandingInterest); // total value of all loans

  return assetsUnderManagement;
};

export const getTotalAssets = (pool: PoolV2WithdrawalFragment): BigNumber => {
  const cash = BigNumber.from(pool.assets);

  let combinedAssets = ZERO;

  if (pool?.openTermLoanManager) {
    const { accountedInterest, domainEnd, domainStart, issuanceRate, principalOut } = pool.openTermLoanManager;
    const openTermAssets = getAssetsUnderManagement(
      accountedInterest,
      domainEnd,
      domainStart,
      issuanceRate,
      principalOut,
      OT_ISSUANCE_RATE_DECIMALS,
    );
    combinedAssets = combinedAssets.add(openTermAssets);
  }

  if (pool.loanManager) {
    const { accountedInterest, domainEnd, domainStart, issuanceRate, principalOut } = pool.loanManager;
    const fixedTermAssets = getAssetsUnderManagement(
      accountedInterest,
      domainEnd,
      domainStart,
      issuanceRate,
      principalOut,
      ISSUANCE_RATE_DECIMALS,
    );
    combinedAssets = combinedAssets.add(fixedTermAssets);
  }

  const totalAssets = cash.add(combinedAssets);

  return totalAssets;
};

export const getTVL = (pool: PoolV2WithdrawalFragment): BigNumber => {
  const { assets, principalOut } = pool;
  const tvl = BigNumber.from(principalOut).add(BigNumber.from(assets));

  return tvl;
};

export const getUtilizationRate = (pool: PoolV2WithdrawalFragment): BigNumber => {
  const { principalOut } = pool;
  const tvl = getTVL(pool);
  const isTVLZero = tvl.eq(ZERO);
  let utilizationRate: BigNumber;
  if (isTVLZero) utilizationRate = ONE_HUNDRED;
  else utilizationRate = BigNumber.from(principalOut).mul(TEN.pow(UTILIZATION_RATE_DECIMALS)).div(tvl);

  return utilizationRate;
};

// Convert shares to assets; excludes impairment from total assets
export const convertToExitAssets = async (
  shares: BigNumber,
  pool: PoolV2WithdrawalFragment | PoolV2EarnFragment | PoolV2DelegateFragment,
): Promise<BigNumber> => {
  try {
    const provider = new JsonRpcProvider(RPC_URL);
    const contract = new Contract(pool.id, ['function convertToExitAssets(uint256) view returns (uint256)'], provider);

    const exitAssets = await contract.convertToExitAssets(shares);

    return exitAssets;
  } catch (e: any) {
    console.error('🚨 convertToExitAssets ::: ', { e });
    throw new Error(e);
  }
};

// Convert assets to shares; excludes impairment from total assets
export const convertToExitShares = async (
  assets: BigNumber,
  pool: PoolV2WithdrawalFragment | PoolV2EarnFragment,
): Promise<BigNumber> => {
  try {
    const provider = new JsonRpcProvider(RPC_URL);
    const contract = new Contract(pool.id, ['function convertToExitShares(uint256) view returns (uint256)'], provider);

    const exitAssets = await contract.convertToExitShares(assets);

    return exitAssets;
  } catch (e: any) {
    console.error('🚨 convertToExitShares ::: ', { e });
    throw new Error(e);
  }
};

// Convert shares to assets; excludes impairment from total assets
export const convertToAssets = async (shares: BigNumber, pool: PoolV2WithdrawalFragment): Promise<BigNumber> => {
  try {
    const provider = new JsonRpcProvider(RPC_URL);
    const contract = new Contract(pool.id, ['function convertToAssets(uint256) view returns (uint256)'], provider);

    const exitAssets = await contract.convertToAssets(shares);

    return exitAssets;
  } catch (e: any) {
    console.error('🚨 convertToAssets ::: ', { e });
    throw new Error(e);
  }
};

export const getWithdrawAlertProps = (
  theme: Theme,
  countdown: CountdownToTimestamp,
  windowState: WindowStates,
): {
  color: 'info' | 'secondary';
  style: SxProps;
  dayLabel: string;
  hoursLabel: string;
  minutesLabel: string;
  copyStart: string;
  copyEnd: string;
} => {
  const isUpcoming = windowState === 'upcoming';
  const color = isUpcoming ? 'info' : 'secondary';
  const style: SxProps = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '6px',
    textAlign: 'center',
    padding: '10px',
    backgroundColor: isUpcoming ? theme.palette.ds.info[100] : theme.palette.ds.secondary[50],
    borderColor: isUpcoming ? theme.palette.ds.info[200] : theme.palette.ds.secondary[100],
  };
  const dayLabel = countdown.days === 1 ? 'Day' : 'Days';
  const hoursLabel = countdown.hours === 1 ? 'Hour' : 'Hours';
  const minutesLabel = countdown.minutes === 1 ? 'Minute' : 'Minutes';
  const copyStart = isUpcoming ? 'Return in' : 'You have';
  const copyEnd = isUpcoming ? 'to withdraw your funds' : 'remaining to withdraw your funds';

  return {
    color,
    style,
    dayLabel,
    hoursLabel,
    minutesLabel,
    copyStart,
    copyEnd,
  };
};

export const isCashManagementUSDC = (id: string): boolean => {
  const { cashUSDC, cashUSDCBase } = POOL_IDS;

  return [cashUSDC, cashUSDCBase].includes(id.toLowerCase());
};

export const isCashManagementUSDT = (id: string): boolean => {
  return id.toLowerCase() === POOL_IDS.cashUSDT;
};

export const isCicada = (id: string): boolean => {
  return id.toLowerCase() === POOL_IDS.cicada;
};

export const isMapleDirect = (id: string): boolean => {
  const { highYieldUSDC1, highYieldWETH, lendLongApr25, lendLongMay25, mapleDirect, opportunistic } = POOL_IDS;

  return [highYieldUSDC1, highYieldWETH, lendLongApr25, lendLongMay25, mapleDirect, opportunistic].includes(
    id.toLowerCase(),
  );
};

export const isOrthogonal = (id: string): boolean => {
  return id.toLowerCase() === POOL_IDS.orthogonal;
};

// Common utility functions
const isPermittedAddress =
  <T extends { poolMeta?: { permittedAddresses?: string[] | null } | null }>(account?: string | null) =>
  (pool: T) =>
    !pool.poolMeta?.permittedAddresses?.length ||
    pool.poolMeta.permittedAddresses.some(item => item.toLowerCase() === account?.toLowerCase());

const isNotHidden = <T extends { poolMeta?: { hidden?: boolean } | null }>(pool: T) => !pool.poolMeta?.hidden;

// Pool Dashboard dropdown
type CombinedPoolTypes = (PoolV2OverviewFragment | PoolV2BasicFragment) & PoolV2ApyCachedFragment;
export const filterAndSortPoolV2Overview = (
  pools: CombinedPoolTypes[],
  selectedPoolId: string,
  account?: string | null,
) => {
  const isActivePool = ({ poolMeta }: CombinedPoolTypes) => poolMeta?.state === PoolV2State.Active;

  const combinedSortOverview = (pool1: CombinedPoolTypes, pool2: CombinedPoolTypes) => {
    const pool1Inactive = pool1.poolMeta?.poolName?.toLowerCase().includes('inactive') ? 0 : 1;
    const pool2Inactive = pool2.poolMeta?.poolName?.toLowerCase().includes('inactive') ? 0 : 1;

    const inactiveComparison = pool2Inactive - pool1Inactive;

    if (inactiveComparison !== 0) return inactiveComparison;

    const pool1IsTreasury = pool1.poolMeta?.isTreasuryPool ? 1 : 0;
    const pool2IsTreasury = pool2.poolMeta?.isTreasuryPool ? 1 : 0;

    const treasuryComparison = pool2IsTreasury - pool1IsTreasury;

    if (treasuryComparison !== 0) return treasuryComparison;

    return +(pool2?.monthlyApy || '0') - +(pool1?.monthlyApy || '0');
  };

  const assembledPools = [...pools]
    .filter(isPermittedAddress(account))
    .filter(isNotHidden)
    .filter(isActivePool)
    .sort(combinedSortOverview);

  const selectedPoolIndex = assembledPools.findIndex(({ id }) => id.toLowerCase() === selectedPoolId.toLowerCase());

  if (selectedPoolIndex > 0) {
    const selectedPool = assembledPools.splice(selectedPoolIndex, 1)[0];
    assembledPools.unshift(selectedPool);
  }

  return assembledPools;
};

export type PoolWithdrawalType = 'cyclical' | 'queue';

export const getPoolWithdrawalType = (
  pool: PoolV2EarnFragment | PoolV2WithdrawalFragment | PoolV2OverviewFragment | PoolV2DelegateFragment,
): PoolWithdrawalType => {
  let withdrawalType: PoolWithdrawalType = 'cyclical';

  if (pool.withdrawalManagerQueue?.id) withdrawalType = 'queue';

  return withdrawalType;
};

export const getWithdrawalSharesAdjustment = async (
  poolV2: PoolV2DelegateFragment | null | undefined,
): Promise<BigNumber> => {
  if (!poolV2) return ZERO;

  const { loanManager, openTermLoanManager } = poolV2 ?? {
    loanManager: { issuanceRate: ZERO },
    openTermLoanManager: { issuanceRate: ZERO },
  };

  // Adjust decimals of OTLoanManager issuance rate as it has 27 decimals while FTLoanManager issuance rate has 30 decimals
  const issuanceRateDecimalsAdjustment = TEN.pow(ISSUANCE_RATE_DECIMALS - OT_ISSUANCE_RATE_DECIMALS);
  const OTLoanManagerIssuanceRate = BigNumber.from(openTermLoanManager?.issuanceRate).mul(
    issuanceRateDecimalsAdjustment,
  );
  const FTLoanManagerIssuanceRate = BigNumber.from(loanManager?.issuanceRate);
  const totalIssuanceRate = OTLoanManagerIssuanceRate.add(FTLoanManagerIssuanceRate);

  // Transform Issuance Rate to LiquidityAsset decimals
  const liquidityAssetDecimalsAdjustment = TEN.pow(ISSUANCE_RATE_DECIMALS);
  const issuanceRate = totalIssuanceRate.div(liquidityAssetDecimalsAdjustment);

  // We assume a tx might take up to say 2 minutes to mine
  const assetsAdjustment = issuanceRate.mul(SECONDS_IN_MINUTE * 2);

  const shares = await convertToExitShares(assetsAdjustment, poolV2);

  return shares;
};

export const getWithdrawalTime = (withdrawalDays?: number | null, withdrawalHours?: number | null) => {
  const wDays = withdrawalDays || 0;
  const wHours = withdrawalHours || 0;
  const withdrawalValue = wDays > 0 ? wDays : wHours;
  const withdrawalUnit = wDays > 0 ? pluralize(wDays, 'day') : pluralize(wHours, 'hour');

  return { withdrawalValue, withdrawalUnit };
};

export const getPoolCardProps = (
  poolsMeta: PoolMetaV2Fragment,
  poolV2S: (PoolV2LenderPoolCardFragment & PoolV2ApyCachedFragment)[],
) => {
  const isUpcomingPool = Boolean(poolsMeta.isUpcoming);
  let apy = poolsMeta.benchmarkApy ?? '';

  if (!isUpcomingPool && poolsMeta.contractAddress && poolV2S) {
    const poolV2 = poolV2S.find(({ id }) => id.toLowerCase() === poolsMeta.contractAddress?.toLowerCase());
    const poolApy = poolV2
      ? BigNumber.from(
          getMonthlyWeeklyOrBenchmarkApy(poolV2.id, poolV2.monthlyApy, poolV2.weeklyApy, poolV2.poolMeta?.benchmarkApy),
        )
      : ZERO; // includes collateralApy
    apy = buildPercentageValueInterfaceFromBigNumber(poolApy, APY_DECIMALS, PERCENTAGE_DECIMALS).formatted;
  }

  return {
    title: poolsMeta.poolName || '',
    delegateName: poolsMeta.poolDelegate.companyName || '',
    assetSymbol: poolsMeta.asset || '',
    poolDescription: poolsMeta.cardDescription || '',
    apy,
    poolId: isUpcomingPool ? poolsMeta._id || '' : poolsMeta.contractAddress || '',
    image: poolsMeta.image || '',
    isUpcomingPool,
  };
};

export const getCarouselPools = (poolsMeta: PoolMetaV2Fragment[], poolV2S: PoolV2LenderPoolCardFragment[]) => {
  return poolsMeta
    .filter(poolMeta => poolMeta?.carouselPriority)
    .sort((a, b) => (a?.carouselPriority ?? 0) - (b?.carouselPriority ?? 0))
    .map(poolMeta => getPoolCardProps(poolMeta, poolV2S));
};

export const checkIsLendLongPool = (poolId: string) => [POOL_IDS.lendLongApr25, POOL_IDS.lendLongMay25].includes(poolId);
