import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

// Schema
import { PoolMetaV2Fragment, PoolV2ApyCachedFragment, PoolV2EarnFragment } from 'Graphql/schema-v2';

// Constants
import { APY_DECIMALS, PERCENTAGE_DECIMALS, PERCENTAGE_INPUT_DECIMALS, POOL_IDS, RATE_DECIMALS, TEN } from 'Constants';

// Pool(s) where we wish to show Benchmark APY instead of Monthly APY
// TODO: add feature to Dashboard to set showing current vs. monthly APY for pools
const showBenchmarkApy = (poolId: string): boolean => {
  return [''].includes(poolId); // e.g. [POOL_IDS.mapleDirect, POOL_IDS.highYieldUSDC1]
};

export function getMonthlyWeeklyOrBenchmarkApy(
  poolId: string,
  monthlyApy: PoolV2ApyCachedFragment['monthlyApy'],
  weeklyApy: PoolV2ApyCachedFragment['weeklyApy'],
  benchmarkApy: PoolMetaV2Fragment['benchmarkApy'],
): string {
  const safeFactor = 10 ** PERCENTAGE_DECIMALS;
  const benchmarkApySafe = +(benchmarkApy || '0') * safeFactor;
  const benchmarkApyScaleBN = parseUnits(benchmarkApySafe.toString(), APY_DECIMALS - PERCENTAGE_INPUT_DECIMALS);
  const benchmarkApyScaled = benchmarkApyScaleBN.toString();

  // Use Benchmark APY if flagged, otherwise use Monthly APY if non-zero, or fall back to Weekly APY if non-zero, or fall back to Benchmark APY
  if (showBenchmarkApy(poolId)) return benchmarkApyScaled;

  return [monthlyApy, weeklyApy, benchmarkApyScaled].find(apy => apy && apy !== '0') || '0';
}

export function getApyLabel(poolId: string): string {
  if ([POOL_IDS.lendLongApr25, POOL_IDS.lendLongMay25].includes(poolId)) return 'APY up to';

  return showBenchmarkApy(poolId) ? 'Benchmark APY' : '30-Day APY';
}

export function getApyTooltip(poolId: string): string {
  return showBenchmarkApy(poolId) ? 'The benchmark APY.' : 'The annualized average APY over the last 30 days.';
}

export function getApyDescription(poolId: string): string[] {
  return showBenchmarkApy(poolId)
    ? [
        'Interest rate calculated as a benchmark return for each lender. This number takes into account all fees charged in the pool.',
        'Interest earned is automatically compounded back into the pool to maximize earnings.',
      ]
    : [
        'Interest rate calculated as an average return for each lender over the past 30 days. This number takes into account all fees charged in the pool.',
        'Interest earned is automatically compounded back into the pool to maximize earnings.',
      ];
}

function calculateGrossApy(
  apy: string,
  delegateManagementFeeRate: string,
  platformManagementFeeRate: string,
): BigNumber {
  const managementFeeRate = BigNumber.from(delegateManagementFeeRate).add(BigNumber.from(platformManagementFeeRate));
  const SCALED_ONE = TEN.pow(RATE_DECIMALS);
  const numerator = BigNumber.from(apy).mul(SCALED_ONE);
  const denominator = SCALED_ONE.sub(managementFeeRate);

  return numerator.div(denominator); // grossApy = apy / (1 - managementFeeRate)
}

interface ApyMetrics {
  managementFees: string;
  grossApy: string;
}
function calculateApyMetrics(pool: PoolV2EarnFragment & PoolV2ApyCachedFragment): ApyMetrics {
  const { id: poolId, monthlyApy, weeklyApy, delegateManagementFeeRate, platformManagementFeeRate, poolMeta } = pool;
  const apy = getMonthlyWeeklyOrBenchmarkApy(poolId, monthlyApy, weeklyApy, poolMeta?.benchmarkApy);

  if (apy === '0')
    return {
      managementFees: '0',
      grossApy: '0',
    };

  const grossApy = calculateGrossApy(apy, delegateManagementFeeRate, platformManagementFeeRate);
  const managementFees = grossApy.sub(BigNumber.from(apy));

  return {
    managementFees: managementFees.toString(),
    grossApy: grossApy.toString(),
  };
}

export function getManagementFees(pool: PoolV2EarnFragment & PoolV2ApyCachedFragment): string {
  return calculateApyMetrics(pool).managementFees;
}

export function getGrossApy(pool: PoolV2EarnFragment & PoolV2ApyCachedFragment): string {
  return calculateApyMetrics(pool).grossApy;
}
