import { useRef } from 'react';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import cloneDeep from 'lodash.clonedeep';

/**
 * A hook that checks if data has changed since the last time it was checked.
 * Supports deep object paths using dot notation (e.g., 'account.currentAverageApy')
 *
 * @param data - The data to check for changes
 * @param options - Configuration options
 * @param options.compareKeys - Optional array of keys (supports deep paths) to specifically compare
 * @param options.ignoreKeys - Optional array of keys (supports deep paths) to ignore in comparison
 * @returns boolean indicating whether the data has changed
 */
export function useHasDataChanged<T>(
  data: T | undefined | null,
  options?: {
    compareKeys?: string[];
    ignoreKeys?: string[];
  },
): boolean {
  // Store previous data for comparison
  const prevDataRef = useRef<any>(null);

  // If there's no data, no change has occurred
  if (!data) return false;

  // Process the data based on options
  const currentData = (() => {
    let processedData: T | Partial<T> = cloneDeep(data);

    if (options?.compareKeys) {
      // First, only include the compareKeys
      processedData = options.compareKeys.reduce((obj, path) => {
        const value = get(data, path);
        set(obj, path, value);

        return obj;
      }, {} as Partial<T>);
    }

    if (options?.ignoreKeys) {
      // Then, remove the ignoreKeys from the result
      const filteredData = JSON.parse(JSON.stringify(processedData));
      options.ignoreKeys.forEach(path => {
        unset(filteredData, path);
      });

      return filteredData;
    }

    return processedData;
  })();

  // Check if data has changed using deep comparison
  const dataChanged = !isEqual(prevDataRef.current, currentData);

  // Log what changed if there were changes and we have previous data
  // if (dataChanged && prevDataRef.current) {
  //   console.group('Data changed:');
  //   console.log({ options });

  //   const logChanges = (obj1: any, obj2: any, path = '') => {
  //     const allKeys = new Set([...Object.keys(obj1 || {}), ...Object.keys(obj2 || {})]);

  //     allKeys.forEach(key => {
  //       const currentPath = path ? `${path}.${key}` : key;
  //       const value1 = get(obj1, key);
  //       const value2 = get(obj2, key);

  //       if (!isEqual(value1, value2)) {
  //         if (typeof value1 === 'object' && typeof value2 === 'object') {
  //           console.group(currentPath);
  //           logChanges(value1, value2, currentPath);
  //           console.groupEnd();
  //         } else {
  //           console.log(`${currentPath}:`, value1, '→', value2);
  //         }
  //       }
  //     });
  //   };

  //   logChanges(prevDataRef.current, currentData);
  //   console.groupEnd();
  // }

  // Update reference for next comparison
  prevDataRef.current = currentData;

  return dataChanged;
}
