import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { xirr } from '@webcarrot/xirr';

// Constants
import { ISSUANCE_RATE_DECIMALS, PERMISSION_LEVELS, ZERO } from 'Constants';

// Interfaces
import {
  AccountTransactionFragment,
  AssetBasicFragment,
  PoolV2EarnFragment,
  PoolV2PositionEarnFragment,
  TxType,
} from 'Graphql/schema-v2';

// Utils
import { convertToAssets } from 'Utils/pool';
import { getUsdcValueByAsset } from './assets';
import { getNowTimestamp } from './dates';

// Average APY
// Calculate user average realized APY = XIRR of cash flows
// Cash flows = user deposits & withrawals in Assets + current user Balance in Assets (amount of LP tokens converted to exit assets)
// Returns BigNumber in default representation e.g. 0.1 for 10%
export const getAverageAPY = (
  txes: AccountTransactionFragment[],
  lendingBalance: BigNumber,
  currentApy: BigNumber,
  asset: AssetBasicFragment,
): BigNumber => {
  const transactions = txes
    .filter(({ type }) =>
      [
        TxType.PoolDeposit,
        TxType.PoolTransferIn,
        TxType.PoolTransferOut,
        TxType.PoolWithdraw,
        TxType.PoolWithdrawPartial,
        TxType.PoolWithdrawFunds,
      ].includes(type),
    )
    .sort((a, b) => a.timestamp - b.timestamp)
    .map(({ type, amount, decimals, timestamp }) => {
      const amountFormatted = formatUnits(BigNumber.from(amount), decimals);

      return {
        amount: [TxType.PoolDeposit, TxType.PoolTransferIn].includes(type) ? -amountFormatted : +amountFormatted,
        date: new Date(+timestamp * 1000),
      };
    });

  // As the final pro forma cash flow, we push the current user's balance in assets value
  // So the APY calc is pro forma, i.e. includes unrealized gains
  transactions.push({
    amount: +formatUnits(lendingBalance, asset.decimals),
    date: new Date(getNowTimestamp() * 1000),
  });

  try {
    const internalRateReturn = xirr(transactions);

    if (internalRateReturn > 1) return ZERO;

    const bigNumber = parseUnits(internalRateReturn.toString(), ISSUANCE_RATE_DECIMALS);

    return bigNumber;
  } catch (_) {
    // Return currentApy if error occurs (notes from package docs):
    // - date the amounts of the transactions are all the same sign
    // - date there are fewer than two transactions
    // - date the transactions all occur on the same day (time is ignored)
    // - date the newton-raphson-method fails to converge
    return currentApy;
  }
};

// Returns total interest earned (claimed + unclaimed that got reinvested) from v1 pool
export const getInterestEarned = (txes: AccountTransactionFragment[]): BigNumber => {
  const interestEarned = txes
    .filter(({ type }) => [TxType.PoolWithdrawFunds, TxType.PoolReinvestInterest].includes(type))
    .reduce((acc, { amount }) => acc.add(BigNumber.from(amount)), ZERO);

  return interestEarned;
};

export const getLossesIncurredUsdc = (txes: AccountTransactionFragment[], assets: AssetBasicFragment[]): BigNumber => {
  const lossesIncurred = txes
    .filter(({ type }) => type === TxType.PoolRecognizeLosses)
    .reduce((acc, { amount, symbol }) => {
      let value = BigNumber.from(amount);
      if (symbol === 'WETH') {
        const asset = assets.find(a => a.symbol === symbol);
        if (asset) {
          value = getUsdcValueByAsset(value, asset);
        }
      }

      return acc.add(value);
    }, ZERO);

  return lossesIncurred;
};

export const getLossesIncurred = (txes: AccountTransactionFragment[]): BigNumber => {
  const lossesIncurred = txes
    .filter(({ type }) => type === TxType.PoolRecognizeLosses)
    .reduce((acc, { amount }) => acc.add(amount), ZERO);

  return lossesIncurred;
};

// Returns gains from v2 pool
export const getGains = async (pool: PoolV2EarnFragment, position: PoolV2PositionEarnFragment): Promise<BigNumber> => {
  const { shares, lockedShares, depositedAssets, withdrawnAssets } = position;
  const allShares = BigNumber.from(shares).add(BigNumber.from(lockedShares));
  const currentAssets = await convertToAssets(allShares, pool);

  const gains = BigNumber.from(currentAssets).add(BigNumber.from(withdrawnAssets)).sub(BigNumber.from(depositedAssets));

  return gains;
};

// Returns total interest earned (claimed and unclaimed) from v1 pool plus gains from v2 pool, denominated in USD
export const getTotalInterestEarned = async (
  pool: PoolV2EarnFragment,
  position: PoolV2PositionEarnFragment,
  txes: AccountTransactionFragment[],
): Promise<BigNumber> => {
  const interestEarned = getInterestEarned(txes); // v1 pool
  const gains = await getGains(pool, position); // v2 pool
  const losses = getLossesIncurred(txes); // losses across v1 and v2 pools

  const totalInterestEarned = interestEarned.add(gains).add(losses);

  return totalInterestEarned;
};

export interface AccountHasPermissionProps {
  permissionLevel: number | undefined;
  openToPublic?: boolean;
  poolLevelBitmap: bigint | undefined;
  allowedLPs: string[];
  account: string;
  accountBitmap: bigint | undefined;
}

export const accountHasPermission = ({
  permissionLevel,
  poolLevelBitmap,
  openToPublic,
  allowedLPs,
  account,
  accountBitmap,
}: AccountHasPermissionProps) => {
  if (!account) return false;

  const isAllowedLP = allowedLPs.includes(account.toLowerCase());

  if (isAllowedLP || openToPublic || permissionLevel === PERMISSION_LEVELS.PUBLIC) {
    return true;
  }

  if (permissionLevel === PERMISSION_LEVELS.PRIVATE) return isAllowedLP;

  if (permissionLevel === PERMISSION_LEVELS.FUNCTION_LEVEL) return false; // TODO: FUNCTION_LEVEL

  if (permissionLevel === PERMISSION_LEVELS.POOL_LEVEL) {
    if (!poolLevelBitmap || !accountBitmap) return false;

    return (BigInt(poolLevelBitmap) & BigInt(accountBitmap)) === BigInt(poolLevelBitmap);
  }

  return false;
};
